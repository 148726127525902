<template>
  <div class="index-container">
    <dataFilter ref="dataFilter" :filter="filter">
      <el-button type="primary" @click="handleQuery()">查询</el-button>
      <el-button type="warning" @click="handleExport()">导出</el-button>
    </dataFilter>
    <div class="tab-content">
      <el-table border size="mini" :data="datas" style="width: 100%">
        <el-table-column label="基本信息" show-overflow-tooltip align="center">
          <el-table-column
            prop="Check.Code"
            fixed="left"
            label="测试编号"
            :width="180"
          >
          </el-table-column>
          <el-table-column label="客户姓名" :min-width="80">
            <template slot-scope="scope">
              <div>
                {{ scope.row.Check.Member.RealName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="Check.Member.Mobile"
            label="手机号"
            :width="120"
          >
          </el-table-column>
          <el-table-column prop="Check.Member.Sex" label="性别" :width="60">
          </el-table-column>
          <el-table-column label="报告日期" :min-width="120">
            <template slot-scope="scope">
              <el-tag type="success">{{
                format.dateFormat(scope.row.Check.ReportDate)
              }}</el-tag>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="得分"
          prop="Score"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="得分分级"
          prop="Level"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="最大脂肪氧化强度"
          show-overflow-tooltip
          align="center"
        >
          <el-table-column
            label="摄氧量"
            prop="ZfSy"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="公斤摄氧量"
            prop="ZfSykg"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="心率"
            prop="ZfBpm"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="跑台速度"
            prop="ZfPaoSu"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="脂肪得分"
            prop="ZFScore"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="耗糖量"
            prop="ZfHaoTang"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="减脂量"
            prop="ZfJianZhi"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="能量消耗"
            prop="ZfXiaoHao"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="无氧阈强度"
          show-overflow-tooltip
          align="center"
        >
          <el-table-column
            label="摄氧量"
            prop="WyySy"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="公斤摄氧量"
            prop="WyySykg"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="心率"
            prop="WyyBpm"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="跑台速度"
            prop="WyyPaoSu"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="耗糖量"
            prop="WyyHaoTang"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="减脂量"
            prop="WyyJianZhi"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="能量消耗"
            prop="WyyXiaoHao"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="最大摄氧量强度"
          show-overflow-tooltip
          align="center"
        >
          <el-table-column
            label="绝对摄氧量"
            prop="MaxSy"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="公斤摄氧量"
            prop="MaxSykg"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="心率"
            prop="MaxSyBpm"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="跑台速度"
            prop="MaxSyPaoSu"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="耗糖量"
            prop="MaxSyHaoTang"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="减脂量"
            prop="MaxSyJianZhi"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="能量消耗"
            prop="MaxSyXiaoHao"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="马拉松成绩"
          show-overflow-tooltip
          align="center"
        >
          <el-table-column
            label="历史最好成绩"
            prop="ScoreMls"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="无氧阈90%绝对摄氧量跑速"
            prop="WyySy90Speed"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="无氧阈90%摄氧量马拉松成绩"
            prop="ScoreWyySy90"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="当前无氧阈值对应理想成绩"
            prop="ScoreWyy"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="当前最大摄氧量对应理想成绩"
            prop="ScoreMaxSy"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="跑步经济性"
          show-overflow-tooltip
          align="center"
        >
          <el-table-column
            label="跑步经济性"
            prop="RunEconomic"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="摄氧量"
            prop="RunSy"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="心率"
            prop="RunBpm"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="跑台速度"
            prop="RunPaoSu"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="耗糖量"
            prop="RunHaoTang"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="减脂量"
            prop="RunJianZhi"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="能量消耗"
            prop="RunXiaoHao"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @size-change="pageSizeChange"
        @current-change="pageCurrentChange"
        :page-sizes="pageSizeList"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :current-page.sync="pageIndex"
      ></el-pagination>
    </div>
  </div>
</template>
  <script>
import dataFilter from "./components/dataFilter.vue";
import { exportData, getPageList } from "@/api/library/checkHeartLung.js";
export default {
  components: { dataFilter },
  data() {
    return {
      loading: false,
      total: 0,
      pageIndex: 1,
      pageSizeList: [10, 20, 30, 50, 80],
      pageSize: 10,
      datas: [],
      filter: {},
    };
  },
  methods: {
    handleExport() {
      this.loadInstance = this.$loading({
        lock: true,
        text: "正在导出...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.filter = this.$refs.dataFilter.getfilter();
      exportData(this.filter)
        .then((res) => {
          this.loadInstance.close();
          if (res) {
            window.open(this.baseUrl + res);
          }
        })
        .catch((ex) => {
          this.msg.errorMsg("导出出错：" + ex);
          this.loadInstance.close();
        });
    },
    handleQuery() {
      let tempfilter = this.$refs.dataFilter.getfilter();
      this.filter = tempfilter;
      this.getData();
    },
    getData() {
      this.filter.pageIndex = this.pageIndex;
      this.filter.pageSize = this.pageSize;
      this.loading = true;
      getPageList(this.filter)
        .then((res) => {
          this.loading = false;
          if (res) {
            this.datas = res.Items;
            this.total = res.Total || res.Items.length;
          }
        })
        .catch((ex) => {
          this.loading = false;
          this.total = 0;
          this.datas = [];
        });
    },
    pageSizeChange(val) {
      var pageSize = val;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.getData());
    },
    pageCurrentChange(val) {
      this.pageIndex = val;
      this.getData();
    },
  },
  mounted() {
    this.handleQuery();
  },
};
</script>
  <style lang="scss" scoped>
.index-container {
  .pagination {
    margin-top: 10px;
    text-align: right;
  }
  padding: 10px;
  .input {
    width: 150px;
  }
  .tabs-container {
    /deep/.el-tabs--border-card > .el-tabs__content {
      padding: 0;
      height: 1px;
    }
    .tab-content {
      margin-top: -2px;
      border: 1px solid #dcdfe6;
      border-top: 0;
      padding: 15px;
      background: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    }
  }
}
</style>